const currencySymbol = process.env.VUE_APP_DEFAULT_CURRENCY_SYMBOL;

const formatCurrency = {
  install: (Vue) => {
    Vue.prototype.$formatCurrency = (value) => {
      if (value == null) {
        return "";
      }
      return `${parseFloat(value).toFixed(2)} ${currencySymbol} `;
    };
  },
};

export default formatCurrency;
