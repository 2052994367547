import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/equipments`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/equipments/${id}?include=organization,categoryEquipment,cafeteria,serviceCenter,establishment,supplier`, options)
    .then((response) => {
      let customer = jsona.deserialize(response.data);
      delete customer.links;
      return customer;
    });
}

function add(warehouse) {
  const payload = jsona.serialize({
    stuff: warehouse,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/equipments?include=`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(warehouse) {
  const payload = jsona.serialize({
    stuff: warehouse,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/equipments/${warehouse.id}?include=`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/equipments/${id}`, options);
}

function print(params) {
  const options = {
    headers: {},
    responseType: "blob",
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/equipments/print`, options);
}



export default {
  print,
  list,
  get,
  add,
  update,
  destroy,
};
