export default {
  CASH_REGISTERS_LIST: "Liste des caisses",
  ADD_CASH_REGISTER: "Ajouter une caisse",
  CASH_REGISTER_ADDED: "caisse ajoutée",
  EDIT_CASH_REGISTER: "Modifier la caisse",
  CASH_REGISTER_UPDATED: "caisse mise à jour",
  DELETE_THIS_CASH_REGISTER: "Supprimer cette caisse ?",
  CASH_REGISTER_DELETED: "caisse supprimée",
  STARTS_AT: "Début",
  ENDS_AT: "Fin",
  TITLE: "Titre",
  CASH_REGISTER_CATEGORY_PEDAGO: "Pédagogie",
  EVENT_CATEGORY_THEMATIC_MEAL: "Repas Thématique",
  EVENT_CATEGORY_TASTING: "Dégustation",
  EVENT_CATEGORY_PROMOTION: "Promotion",
  EVENT_CATEGORY_EVENT: "Événement",
  EVENT_CATEGORY_INTERNAL_JOURNAL: "Journal Interne",
  EVENT_CATEGORY_CLIENT_JOURNAL: "Journal Client",
  EVENT_CATEGORY_SURVEY: "Sondage",
  EVENT_CATEGORY_MONTHLY_INSPIRATION: "Inspiration du Mois",
  EVENT_CATEGORY_OPEN_HOUSE: "Porte-Ouverte",
  SHOW_MY_EVENTS: "Voir les caisses qui me concernent",
  SHOW_ALL: "Voir tous les caisses"
};
