<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <sidebar-item
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_CAFETERIAS)"
          true
          :link="{
            name: $t('COMMON.CAFETERIAS'),
            icon: 'ni ni-shop text-primary',
            path: '/cafeteria/cafeterias',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_ESTABLISHMENTS)"
          true
          :link="{
            name: $t('COMMON.ESTABLISHMENTS'),
            icon: 'ni ni-building text-primary',
            path: '/cafeteria/establishments',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SERVICECENTERS)"
          true
          :link="{
            name: $t('COMMON.SERVICECENTERS'),
            icon: 'ni ni-square-pin text-primary',
            path: '/cafeteria/service-centers',
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SERVICECENTERS)"
          true
          :link="{
            name: $t('COMMON.WEEKLY_REPORTS'),
            icon: 'ni ni-calendar-grid-58 text-primary',
            path: '/cafeteria/weekly-reports',
          }"
        >
        </sidebar-item>

        <hr
          class="my-3"
          style="
            border-color: rgba(0, 0, 0, 0.1) currentcolor currentcolor;
            border-style: solid none none;
            border-width: 1px 0px 0px;
            border-image: none 100% / 1 / 0 stretch;
            min-width: 80%;
            overflow: visible;
            box-sizing: content-box;
            height: 0px;
          "
        />

        <lang-switcher />
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar
        :type="$route.meta.navbarType"
        :appModuleIcon="'ni ni-2x ni-shop'"
        :appModuleName="$t('APPS.CAFETERIA_MODULE')"
      ></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}
import { LangSwitcher } from "@/components";
import { loadLanguageAsync } from "@/plugins/i18n";
import DashboardNavbar from "../../Layouts/Authenticated/DashboardNavbar.vue";
import ContentFooter from "../../Layouts/Authenticated/ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";
import Vuex from "vuex";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
    LangSwitcher,
  },
  mixins: [],
  data() {
    return {
      roles: [],
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  async mounted() {
    this.initScrollbar();
    try {
      await this.$store.dispatch("profile/me");
      const me = await this.$store.getters["profile/me"];
      if (this.$i18n.locale !== me.locale) {
        loadLanguageAsync(me.locale);
      }
    } catch (e) {
      if (e.response) {
        if ([401, 403].includes(e.response.status)) {
          this.$store.dispatch("logout");
        }
      } else {
        throw e;
      }
    }
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },
  watch: {
    me: {
      handler: function (val) {
        this.roles = val.roles.map((r) => r.name);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
body.g-sidenav-hidden {
  #purchase-button,
  #demo-button,
  #star-button,
  #docs-button {
    margin-left: 8px;
    margin-right: 8px;
    padding: 11px 0px;
  }
}
body.g-sidenav-show {
  #purchase-button,
  #demo-button,
  #star-button,
  #docs-button {
    margin-left: 20px;
    margin-right: 20px;
    padding: 11px 0px;
  }
}
</style>
