export default {
    STARTS_AT: "Début",
    ENDS_AT: "Fin",
    COUNTER_SALES: "Ventes comptoir",
    BILLING: "Facturation",
    DISTRIBUTOR_SALES: "Ventes distributrice",
    COUNTER_PURCHASE: "Achat comptoir",
    STATIONERY: "Papeterie",
    CLEANING_AND_DISPOSABLE_PRODUCTS: "Prod. Nettoyant et jetable ",
    OTHERS: "Autres",
    MAINTENANCE: "Entretien",
    DISTRIBUTOR: "Distributrice",
    WAGES: "Salaires",
    RENT: "Loyer",
    WEEKLY_REPORTS_LIST: "Liste des rapports hebdomadaires",
    ADD_WEEKLY_REPORT: "Ajouter un rapport hebdomadaire",
    WEEKLY_REPORT_ADDED: "rapport hebdomadaire ajouté",
    EDIT_WEEKLY_REPORT: "Modifier un rapport hebdomadaire",
    WEEKLY_REPORT_UPDATED: "rapport hebdomadaire mis à jour",
    DELETE_THIS_WEEKLY_REPORT: "Supprimer ce rapport hebdomadaire ?",
    WEEKLY_REPORT_DELETED: "rapport hebdomadaire supprimé",
    SALES: "Ventes",
}