export default {
  SALES_ORDERS_LIST: "Liste des soumissions",
  ADD_SALES_ORDER: "Ajouter une soumission",
  ADD_SALES_ORDER_ITEM: "Ajouter une ligne",
  SALES_ORDER_ADDED: "Soumission ajoutée",
  OWNER: "Propriétaire",
  EDIT_SALES_ORDER: "Modifier une soumission",
  SALES_ORDER_UPDATED: "Soumission mise à jour",
  BILLING_ADDRESS: "Adresse de facturation",
  EXPIRATION_TIME: "Date d'expiration",
  ITEM_TYPE: "Type",
  SELECT_PRODUCTS: "Choisir un produit",
  SELECT_SUBSCRIPTIONS: "Choisir une inscription",
  SELECT_PACKAGES: "Choisir un forfait",
  DELETE_SALES_ORDER_ITEM: "Supprimer la ligne",
  UPDATE_SALES_ORDER_ITEM: "Modifier la ligne",
  SALES_ORDER_ITEM_DELETED: "Ligne supprimée",
  SALES_ORDER_ITEM_ADDED: "Ligne ajoutée",
  SALES_ORDER_ITEM_EDITED: "Ligne modifiée",
  SEND: "Envoyer",
  CANCEL: "Annuler",
  REFUND: "Rembourser",
  ADD_PAYMENT: "Ajouter un paiement",
  SALES_ORDER_SENT: "Soumission envoyée",
  SELECT_USERS: "Choisir l'utilisateur",
  SELECT_CUSTOMERS: "Choisir le client",
  SELECT_ORGANIZATIONS: "Choisir l'organisation",
  SELECT_SUPPLIERS: "Choisir le fournisseur",
  DELETE_THIS_SALES_ORDER: "Supprimer cette soumission ?",
  SALES_ORDER_DELETED: "Soumission supprimée",
  CANCEL_SALES_ORDER_QUESTION: "Annuler la soumission ?",
  SALES_ORDER_CANCELED: "Soumission annulée",
  RECIPIENT: "Destinataire",
  ISSUER: "Emetteur",
  VALIDATE: "Valider",
  SALES_ORDER_VALIDATED: "Soumission validée",
  GENERATE_INVOICE: "Créer la facture",
  INVOICE_GENERATED: "Facture crée",
  VIEW_INVOICE: "Voir la facture",
  ADD_DELIVERY: "Ajouter une livraison",
  DELIVERIES_STATE: "Etat de livraison",
  DELIVERIES_STATE_ITEM: "Produit",
  DELIVERIES_STATE_ORDERED_QUANTITY: "Quantite commandée",
  DELIVERIES_STATE_DELIVERED_QUANTITY: "Quantité livrée",
  DELIVERIES_STATE_REMAINING_QUANTITY: "Quantité restant",
  DELIVERIES_STATE_REMAINING_QUANTITY: "Quantité restant",
  NOT_INVOICED: "A facturer",
  NOT_DELIVERED: "A livrer",
  DELIVERED: "Livré",
  SALES_INVOICES: "Factures vente",
  ADD_INVOICE: "Ajouter une facture",
  INVOICING_TYPE: "Type de facturation",
  INVOICING_TYPE_COMPLETE: "Complète",
  INVOICING_TYPE_AMOUNT: "Montant",
  INVOICING_TYPE_PRODUCT: "Produit",
  INVOICING_STATUS_PENDING: "Non Facturée",
  INVOICING_STATUS_PARTIALLY_INVOICED: "Partiellement facturée",
  INVOICING_STATUS_INVOICED: "Facturée",
  DELIVERY_STATUS_PENDING: "Non Livrée",
  DELIVERY_STATUS_PARTIALLY_DELIVERED: "Partiellement livrée",
  DELIVERY_STATUS_DELIVERED: "Livrée",
  INVOICING_STATUS: "Status facturation",
  ORDER_STATUS_DRAFT: "Brouillon",
  ORDER_STATUS_CANCELED: "Annulée",
  ORDER_STATUS_VALIDATED: "Validée",
  ORDER_STATUS_COMPLETED: "Completée",
  DELIVERY_STATUS: "Status livraison",
  INVOICE_TYPE: "Type de facture",
  INVOICE_TYPE_DOWNPAYMENT: "Facture d'acompte",
  INVOICE_TYPE_STANDARD: "Facture standard",
  SALES_ORDER_INVOICE_ADDED: "Facture ajoutée",
  EVENT_DATE: "Date de l’évènement",
  EVENT_TIME: "Heure de l’évènement",
  DELIVERY_LOCATION: "Local où livrer le traiteur",
  SHOW_SALES_ORDER: "Voir la soumission",
  SALES_ORDER_DESCRIPTION: "Description de la soumission",
  DOWNLOAD_PDF: "Télécharger le pdf",
  PRODUCT_SERVCIE: "Produits/Services",
  QTY: "Qté",
  OWED_AMOUNT: "SOLDE DÛ",
  VALIDATION: "Validation",
  VALIDATION_MESSAGE: "Validez votre soumission maintenant ! Vous trouverez sur cette page tous les détails de votre soumission. Vérifiez correctement les informations",
  COMMENT: "Commentaire",
  ACCEPT_TERMS_AND_CONDITIONS: "Accepter les termes et conditions de soumission",
  ACCEPT: "Accepter",
  REFUSE: "Refuser",
  SALES_ORDER_VALIDATED: "La soumission à bien été validé. Nous vous recontacterons bientôt. Merci.",
  SALES_ORDER_CANCELED: "La soumission à bien été Refusé. Nous vous recontacterons bientôt. Merci.",
  ALERT_VALIDATE: "Voulez-vous vraiment <span>ACCEPTER</span> cette soumission ?",
  ALERT_CANCEL: "Voulez-vous vraiment <span>REFUSER</span> cette soumission ?",
  ACCEPT_VALIDATE: "Oui, accepter",
  ACCEPT_CANCEL: "Oui, refuser",
  CLIENT_VALIDATION: "Validation client",
  CLIENT_VALIDATED_STATUS_0: "",
  CLIENT_VALIDATED_STATUS_DRAFT: "En attente",
  CLIENT_VALIDATED_STATUS_CANCELED: "Refusée",
  CLIENT_VALIDATED_STATUS_VALIDATED: "Acceptée",
  VALIDATED_AT: "Acceptée le ",
  CANCELED_AT: "Refusée le ",
  REVERT_TO_DRAFT: "Remettre en brouillon",
  CONFIRM_REVERT_TO_DRAFT: "Êtes vous sure de vouloir remettre en brouillon",
  PURCHASES_ORDER_REVERTED: "Commande remise en brouillon",
};
