
export default {
  EQUIPMENTS_LIST: "Liste des équipements",
  ADD_EQUIPMENT: "Ajouter un équipement",
  EQUIPMENT_ADDED: "équipement ajouté",
  EDIT_EQUIPMENT: "Modifier un équipement",
  EQUIPMENT_UPDATED: "équipement mis à jour",
  DELETE_THIS_EQUIPMENT: "Supprimer cet équipement ?",
  EQUIPMENT_DELETED: "équipement supprimé",
  MODEL: "Modèle",
  PURCHASE_YEAR: "Date d'achat",
  SERVICE_PLAN: "Plan de service",
  FORMAT : "Format",
  SOFTWARE: "Logiciel",
  LOAN_YEAR: "Date de prêt",

};
