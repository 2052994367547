import service from "@/store/services/sales-orders-service";
import Jsona from "jsona";

const jsona = new Jsona();

export const state = () => ({
  list: {},
  salesOrder: {},
  meta: {},
  url: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, salesOrder) => {
    state.salesOrder = salesOrder;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((salesOrder) => {
      commit("SET_RESOURCE", salesOrder);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((salesOrder) => {
      commit("SET_RESOURCE", salesOrder);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((salesOrder) => {
      commit("SET_RESOURCE", salesOrder);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },

  validate({ commit, dispatch }, params) {
    return service.validate(params, this.$axios).then((salesOrder) => {
      commit("SET_RESOURCE", salesOrder);
    });
  },

  revertToDraft({ commit, dispatch }, params) {
    return service.revertToDraft(params, this.$axios).then((purchasesOrder) => {
      commit("SET_RESOURCE", purchasesOrder);
    });
  },

  cancel({ commit, dispatch }, params) {
    return service.cancel(params, this.$axios).then((salesOrder) => {
      commit("SET_RESOURCE", salesOrder);
    });
  },

  generateInvoice({ commit, dispatch }, params) {
    return service.generateInvoice(params, this.$axios);
  },

  generateDelivery({ commit, dispatch }, params) {
    return service.generateDelivery(params, this.$axios);
  },

  print({ commit, dispatch }, params) {
    return service.print(params, this.$axios);
  },

  sendMail({ commit, dispatch }, params) {
    return service.sendMail(params, this.$axios);
  },

  publicGet({ commit, dispatch }, params) {
    return service.publicGet(params, this.$axios).then((salesOrder) => {
      commit("SET_RESOURCE", salesOrder);
    });
  },

  publicDownloadPdf({ commit, dispatch }, params) {
    return service.publicDownloadPdf(params, this.$axios);
  },

  publicValidate({ commit, dispatch }, params) {
    return service.publicValidate(params, this.$axios).then((salesOrder) => {
      commit("SET_RESOURCE", salesOrder);
    });
  },

  publicCancel({ commit, dispatch }, params) {
    return service.publicCancel(params, this.$axios).then((salesOrder) => {
      commit("SET_RESOURCE", salesOrder);
    });
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  salesOrder: (state) => state.salesOrder,
  url: (state) => state.url,
};

const salesOrders = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default salesOrders;
